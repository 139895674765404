// REACT DEFAULTS & EXTERNAL LIBRARIES
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useCookies } from "react-cookie";

// COMPONENTS, UTILs & LIBs
import { AuthContext } from "../../../context/AppContext";
import CreateProductModal from "./CreateProductsModal";
import UpdateProductModal from "./UpdateProductModal";
import AddProductToAdminsStoreModal from "./AddProductToAdminsStoreModal";
import Table from "../../../components/table/Table";
import {
  ProductResponseData,
  getproducts,
} from "../../../handlers/productHandler";
import { Oval } from "react-loader-spinner";
import { MdAddShoppingCart } from "react-icons/md";

// STYLES
import styles from "./Products.module.css";
import { exportData } from "../../../handlers/exportData";
import { toast } from "../../../components/toast/toast";

const Products = () => {
  const [slug, setSlug] = useState<string>("");
  const [productName, setProductName] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [cookies] = useCookies(["sofresh-admin-token"]);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const { user } = useContext(AuthContext);

  const { data: products, isLoading } = useQuery("products", () =>
    getproducts(cookies["sofresh-admin-token"])
  );

  const [isCreateProductModalOpen, setIsCreateProductModalOpen] =
    useState(false);
  const [isUpdateProductModalOpen, setIsUpdateProductModalOpen] =
    useState(false);
  const [isAddProductToStoreModalOpen, setIsAddProductToStoreModalOpen] =
    useState(false);

  // HANDLES FOR UPDATE MODAL
  const handleOpenCreateProductModal = () => {
    setIsCreateProductModalOpen(true);
  };

  const handleCloseCreateProductModal = () => {
    setIsCreateProductModalOpen(false);
  };

  const handleOpenUpdateProductModal = ({ slug }: { slug: string }) => {
    setSlug(slug);
    setIsUpdateProductModalOpen(true);
  };
  const handleCloseUpdateProductModal = () => {
    setSlug("");
    setIsUpdateProductModalOpen(false);
  };

  const handleOpenAddProductToSubAdminsStoreModal = ({
    slug,
    productName,
    isActive,
  }: {
    slug: string;
    productName: string;
    isActive: boolean;
  }) => {
    setSlug(slug);
    setProductName(productName);
    setIsActive(isActive);
    setIsAddProductToStoreModalOpen(true);
  };
  const handleCloseAddProductToSubAdminsStoreModal = () => {
    setSlug("");
    setIsAddProductToStoreModalOpen(false);
  };

  const handleExportData = async () => {
    try {
      setIsDownloading(true);
      const responseData = await exportData({
        token: cookies["sofresh-admin-token"],
        type: "product",
      });

      if (responseData.status === "downloaded") {
        toast.success("Data successfully downloaded");
      }
    } catch (error) {
      toast.error("Failed to download! Try again.");
    } finally {
      setIsDownloading(false);
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row: IData) => row.name,
      sortable: true,
    },
    {
      name: "Image",
      selector: (row: IData) => (
        <img
          src={row.image[0]}
          alt={row.name}
          style={{ width: "50px", aspectRatio: "1/1" }}
        />
      ),
    },
    {
      name: "Price",
      selector: (row: IData) => `₦${row.price}`,
      sortable: true,
    },
    {
      name: "Available",
      selector: (row: IData) => (
        <span
          className={
            row.is_active
              ? "badge bg-success outline-0 border-0"
              : "badge bg-danger outline-0 border-0"
          }
        >
          {row.is_active ? <span>Yes</span> : <span>No</span>}
        </span>
      ),
      sortable: true,
    },
    {
      name: user?.role === "subadmin" && "In Store",
      selector: (row: IData) =>
        user?.role === "subadmin" && (
          <span
            className={
              row.is_available
                ? "badge bg-success outline-0 border-0"
                : "badge bg-danger outline-0 border-0"
            }
          >
            {row.is_available ? <span>Yes</span> : <span>No</span>}
          </span>
        ),
      sortable: true,
      omit: user?.role === "admin",
    },
    {
      name: "Actions",
      selector: (row: IData) => (
        <div className="flex align-items-center">
          {user?.role === "admin" ? (
            <button
              onClick={() =>
                handleOpenUpdateProductModal({
                  slug: row.slug,
                })
              }
              className={`${styles.updateProductModal} mx-2`}
            >
              <i className="bi bi-pencil-square"></i>
            </button>
          ) : (
            <button
              onClick={() =>
                handleOpenAddProductToSubAdminsStoreModal({
                  slug: row.slug,
                  productName: row.name,
                  isActive: row.is_available,
                })
              }
              className={`bg-transparent outline-0 border-0
                ${row.is_available ? "text-success" : "text-danger"}`}
              style={{ fontSize: "1.2rem" }}
            >
              <MdAddShoppingCart />
            </button>
          )}
        </div>
      ),
      sortable: true,
    },
  ];

  // SEARCH FEATURE
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredProducts, setFilteredProducts] = useState<
    Array<ProductResponseData>
  >([]);

  useEffect(() => {
    // Filter products based on the search query
    const filtered = products?.filter((product: ProductResponseData) =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [products, searchQuery, setFilteredProducts]);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };
  // END OF SEARCH FEATURE

  return (
    <main className="content">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="w-100 col-md-12 d-flex  align-items-center justify-content-between flex-wrap gap-4 py-4">
            <h1 className="h2 m-0">Products</h1>

            <div className="w-75 d-flex  align-items-center justify-content-between gap-3">
              <input
                className="form-control"
                style={{ maxWidth: "60%" }}
                type="text"
                name="search"
                id="search"
                placeholder="Search products"
                value={searchQuery}
                onChange={handleSearch}
              />

              {user?.role === "admin" && (
                <div className="d-flex gap-3">
                  <button
                    onClick={handleExportData}
                    className="btn btn-sm btn-success"
                  >
                    {isDownloading ? (
                      <Oval
                        color="white"
                        wrapperClass="mx-4"
                        height={10}
                        width={10}
                      />
                    ) : (
                      "Export Data"
                    )}
                  </button>

                  <button
                    onClick={handleOpenCreateProductModal}
                    className="btn btn-sm btn-warning"
                  >
                    Create Products <i className="bi bi-bag-plus-fill"></i>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                {isLoading ? (
                  <div className="my-5 w-100 d-flex  align-items-center justify-content-center">
                    <Oval
                      height="40"
                      width="40"
                      color="#495057"
                      visible={true}
                    />
                  </div>
                ) : (
                  <Table data={filteredProducts} columns={columns} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isCreateProductModalOpen && (
        <CreateProductModal
          isOpen={isCreateProductModalOpen}
          onClose={handleCloseCreateProductModal}
        />
      )}

      {isUpdateProductModalOpen && (
        <UpdateProductModal
          isOpen={isUpdateProductModalOpen}
          onClose={handleCloseUpdateProductModal}
          slug={slug}
        />
      )}

      {isAddProductToStoreModalOpen && (
        <AddProductToAdminsStoreModal
          isOpen={isAddProductToStoreModalOpen}
          onClose={handleCloseAddProductToSubAdminsStoreModal}
          slug={slug}
          productName={productName}
          is_active={isActive}
        />
      )}
    </main>
  );
};

export default Products;
