import { useState } from "react";
import { Oval } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import {
  GetBlogPostsData,
  getBlogPosts,
} from "../../../../handlers/blogsHandlers";
import { useCookies } from "react-cookie";
import { useQuery } from "react-query";
import placeholderImage from "../../../../assets/blog_sm.png";
import { CgArrowLongLeft, CgArrowLongRight } from "react-icons/cg";
import styles from "./Blogs.module.css";
import { scrollToTop } from "../../../../utils/helper";

const Blog = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["sofresh-admin-token"]);
  const [prevButtonHovered, setPrevButtonHovered] = useState(false);
  const [nextButtonHovered, setNextButtonHovered] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { data: blogPosts, isLoading: isBlogPostsLoading } = useQuery(
    ["blog-posts", currentPage],
    () =>
      getBlogPosts({
        token: cookies["sofresh-admin-token"],
        page: currentPage,
      })
  );

  const itemsPerPage = blogPosts?.meta?.per_page ?? 10;
  const totalItems = blogPosts?.meta?.total ?? 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleOpenCreateBlogPage = () => {
    navigate("/marketing/blogs/create");
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };

  return (
    <main className={`content ${styles.blogPostBackground}`}>
      <section className="container-fluid p-0">
        <div className="row px-0">
          <div className="w-100 col-md-12 d-flex  align-items-center justify-content-between flex-wrap gap-4 py-4">
            <h1 className="h2 m-0">Blog Post</h1>

            <button
              onClick={handleOpenCreateBlogPage}
              className="btn btn-warning"
            >
              Create Blog Post <i className="bi bi-bag-plus-fill"></i>
            </button>
          </div>
        </div>

        {isBlogPostsLoading ? (
          <div className="my-5 w-100 d-flex  align-items-center justify-content-center">
            <Oval height="40" width="40" color="#495057" visible={true} />
          </div>
        ) : (
          <>
            <div
              className={styles.blogContainer}
              // className="row align-items-start"
              // className='row row-cols-2 align-items-start'
              style={{ minBlockSize: "50dvh" }}
            >
              {blogPosts?.data &&
                blogPosts?.data?.map((blog: GetBlogPostsData) => (
                  <figure
                    className="w-auto col col-md-6 d-flex flex-column flex-md-row  align-items-center justify-content-start gap-2 gap-md-3 gap-xl-4 rounded-3 border border-1"
                    key={blog.id}
                  >
                    <div className={styles.imageContainer}>
                      <img
                        src={blog.images ? blog.images[0] : placeholderImage}
                        alt="blog banner"
                        className="rounded-3"
                        style={{
                          width: "inherit",
                          height: "inherit",
                          objectFit: "cover",
                        }}
                      />
                    </div>

                    <figcaption
                      className="w-100 d-flex flex-column align-items-start justify-content-around pe-3"
                      style={{ height: "150px" }}
                    >
                      <h4 className={styles.blogPostTitle}>{blog.title}</h4>
                      <p className={styles.blogPostDetails}>
                        {blog.category ? blog.category?.title : "Blog Category"}
                      </p>

                      <div className="w-100 d-flex justify-between">
                        <p className={styles.blogPostDetails}>{`Published on ${
                          blog.created_at
                            ? blog.created_at
                            : new Date().toUTCString()
                        }`}</p>

                        <Link to={`/marketing/blogs/${blog.slug}/edit`}>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.7989 14.9337H1.06657V3.20134H8.11663L9.18321 2.13477H1.06657C0.783701 2.13477 0.512414 2.24714 0.312392 2.44716C0.112371 2.64718 0 2.91847 0 3.20134V14.9337C0 15.2165 0.112371 15.4878 0.312392 15.6878C0.512414 15.8879 0.783701 16.0002 1.06657 16.0002H12.7989C13.0818 16.0002 13.3531 15.8879 13.5531 15.6878C13.7531 15.4878 13.8655 15.2165 13.8655 14.9337V6.93435L12.7989 8.00093V14.9337Z"
                              fill="#F9B866"
                            />
                            <path
                              d="M15.746 2.04949L13.9488 0.252308C13.8691 0.172331 13.7743 0.108877 13.67 0.0655816C13.5657 0.0222861 13.4538 0 13.3409 0C13.2279 0 13.1161 0.0222861 13.0118 0.0655816C12.9075 0.108877 12.8127 0.172331 12.7329 0.252308L5.42158 7.60634L4.82963 10.1715C4.80441 10.2958 4.80706 10.4242 4.8374 10.5474C4.86773 10.6706 4.92498 10.7855 5.00504 10.8839C5.08511 10.9823 5.18599 11.0618 5.30043 11.1166C5.41487 11.1713 5.54002 11.2001 5.66689 11.2007C5.73247 11.2079 5.79864 11.2079 5.86421 11.2007L8.45065 10.6301L15.746 3.26538C15.826 3.18562 15.8895 3.09087 15.9328 2.98655C15.976 2.88222 15.9983 2.77038 15.9983 2.65743C15.9983 2.54448 15.976 2.43264 15.9328 2.32832C15.8895 2.224 15.826 2.12924 15.746 2.04949ZM7.89603 9.6435L5.9442 10.0755L6.3975 8.13963L11.901 2.59877L13.4049 4.10264L7.89603 9.6435ZM14.0075 3.50003L12.5036 1.99616L13.3302 1.15356L14.8448 2.6681L14.0075 3.50003Z"
                              fill="#F9B866"
                            />
                          </svg>
                        </Link>
                      </div>
                    </figcaption>
                  </figure>
                ))}
            </div>
          </>
        )}

        <div className="row p-0">
          <div
            className="w-100 col-md-12 d-flex  align-items-center justify-content-between"
            style={{
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "20px",
            }}
          >
            <button
              disabled={currentPage === 1 || isBlogPostsLoading}
              onClick={() => handlePageChange(currentPage - 1)}
              className={`btn btn-link px-4 py-1 d-flex  align-items-center justify-contents-center gap-2 text-decoration-none ${
                currentPage === 1
                  ? `bg-white text-muted`
                  : prevButtonHovered
                  ? "bg-success text-white"
                  : "text-dark"
              }`}
              style={{
                borderRadius: "6px",
                border: "1px solid #D0D5DD",
                transition: "all 300ms ease-in",
              }}
              onMouseEnter={() => setPrevButtonHovered(true)}
              onMouseLeave={() => setPrevButtonHovered(false)}
            >
              <CgArrowLongLeft />
              <span>Previous</span>
            </button>
            <span className="my-5 w-100 d-flex  align-items-center justify-content-center">
              {`Page ${currentPage} of `}
              {totalPages ? (
                totalPages
              ) : (
                <span className="d-inline">
                  <Oval height="15" width="15" color="#495057" visible={true} />
                </span>
              )}
            </span>
            <button
              disabled={currentPage === totalPages || isBlogPostsLoading}
              onClick={() => handlePageChange(currentPage + 1)}
              className={`btn btn-link px-4 py-1 d-flex  align-items-center justify-contents-center gap-2 text-decoration-none ${
                currentPage === totalPages
                  ? `bg-white text-muted`
                  : nextButtonHovered
                  ? "bg-success text-white"
                  : "text-dark"
              }`}
              style={{
                borderRadius: "6px",
                border: "1px solid #D0D5DD",
                transition: "all 300ms ease-in",
              }}
              onMouseEnter={() => setNextButtonHovered(true)}
              onMouseLeave={() => setNextButtonHovered(false)}
            >
              <span>Next</span>
              <CgArrowLongRight />
            </button>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Blog;
